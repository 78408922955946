@import "./assets/fonts.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #e9e9e9;
    border-radius: 4px;
    @media only screen and (max-width: 600px) {
      width: 6px;
      height: 6px;
    }
  }

  :root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #cecece;

    &:hover {
      background-color: #8a8a8a;
    }
  }
}

.semi-bold {
  font-weight: 600;
}
h6 {
  color: #455cd9;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  margin-top: 5px;
}

#root {
  overflow-x: hidden;
}

.app-body {
  padding-bottom: 2rem;
  min-height: 80vh;
  background-color: transparent;
}
.dotted-background {
  background-color: white;
  background-image: radial-gradient(#dddddd 1px, transparent 1px);
  background-size: 18px 18px;
}

// Toast styles
.Toastify__toast {
  font-family: "Open Sans", "Segoe UI", system-ui, sans-serif !important;
  font-weight: 500;
  font-size: 0.9rem;
  box-shadow: 0 0 15px #c9c9c948 !important;
  border-radius: 0.2rem !important;
  padding: 1rem !important;
  outline: 1px solid #eee;
}

.Toastify__toast--error {
  box-shadow: 0 0 8px #ffe0e0 !important;
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__close-button {
  &:hover {
    background: transparent !important;
  }
}
body {
  color: #555;
  user-select: none;
  font-family: "Open Sans", "Segoe UI", system-ui, sans-serif;
}

.disable-scroll {
  overflow: hidden;
}
.skeleton,
.skeleton-without-animation,
.hotevents-skeleton {
  background-color: #bebebe;
  -webkit-box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.2);
  height: 355px;
  width: 250px;
  margin: 10px auto;
  border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
  animation: infiniteAnimation 2s infinite;

  div {
    width: 100%;
    margin: 0 auto;
    height: 30px;
    background-color: #ebebeb;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: #9692927c;
    &:nth-child(1) {
      height: 75%;
      margin: 0 auto 10px auto;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:nth-child(3) {
      width: 70%;
      margin: 0 auto;
    }
  }
}

.hotevents-skeleton {
  height: 150px;

  div {
    &:nth-child(1) {
      height: 69%;
    }
  }
}

.skeleton-without-animation {
  animation: none;
  background-color: rgba(190, 190, 190, 0.17);

  div {
    background-color: rgba(150, 146, 146, 0.174);
  }
}

/* hide arrows
 Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}

input:focus,
textarea:focus {
  outline: none;
}

@keyframes infiniteAnimation {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}
small {
  opacity: 0.9;
}

.navlink-active {
  background-color: rgba(255, 255, 255, 0.115);
}

.highlighted {
  fill: rgba(244, 206, 70, 0.794);
}

.done {
  fill: hsl(94, 100%, 50%);
}

// GLobal setting
@media only screen and (max-width: 450px) {
  h6 {
    font-size: 15px !important;
  }
}

// RECAPTCH
.rc-anchor {
  border: none;
  border-radius: 15px !important;
}

// MODAL
body.modal-open {
  overflow: hidden; // Fully disables scrolling when modal is open
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.flex-grow {
  flex-grow: 1;
}
.modal-container-custom {
  background: #fff;
  padding: 1.2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  transform: translateY(-20px);
  width: min(500px, 96vw);
  overflow: auto;
  height: 95vh;

  .modal-backdrop.show & {
    transform: translateY(-5px);
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;

    .modal-title {
      font-size: 18px;
      font-weight: bold;
    }

    .modal-close {
      background: none;
      border: none;
      cursor: pointer;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: red;
      }
    }
  }

  .modal-body {
    padding-top: 15px;
  }
}

@import "Navbar.scss";
@import "Footer.scss";
@import "ListPage";
@import "Hero.scss";
@import "ThisWeek.scss";
@import "SinglePage.scss";
@import "Login.scss";
@import "Buy.scss";
@import "ThankYou.scss";
@import "Info";
@import "Error";
@import "DrawPlace";
@import "Loader";
@import "OrderConfirmation";

@import "profile-styles/AddTickets";
@import "profile-styles/MyEvent";
@import "profile-styles/Profile";
@import "profile-styles/EntranceController";
@import "profile-styles/AddHall";
@import "profile-styles/MyTickets";
@import "profile-styles/UserManager";
@import "profile-styles/RequestResseler";
@import "profile-styles/ResellerRequests";
@import "profile-styles/AddReseller";
@import "profile-styles/CheckResellerTickets";
@import "profile-styles/SponsorModal";
@import "profile-styles/VerifyEvent";
@import "profile-styles/Newsletter";
@import "profile-styles/AdminTickets";
@import "profile-styles/UpdateEvent";
@import "profile-styles/DevExpress";
@import "profile-styles/LiveEntrance";
@import "profile-styles/PrintTicketManagerModal";
@import "profile-styles/PaymentRequestTable";
@import "profile-styles/Loading";
@import "profile-styles/SessionExpire";
