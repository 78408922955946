.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;

  .spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top-color: #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  p {
    margin-top: 15px;
    font-size: 18px;
    color: #555;
    font-weight: 600;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
