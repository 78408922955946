.session-expired-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
  padding: 20px;

  h2 {
    font-size: 24px;
    color: #d9534f;
  }

  p {
    font-size: 16px;
    color: #555;
    max-width: 400px;
    margin: 10px 0 20px;
  }
}
