.profile .profile-form .choose-concert {
  h6 {
    color: #455cd9;
    padding: 10px;
  }
  .select-event {
    max-width: 100%;
  }
}

.concert-container {
  .refresh-button {
    margin: 5px 0;
    position: absolute;
    right: 3%;
    top: 10%;
    cursor: pointer;
    font-size: 1rem;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    font-size: 0.9rem;
    font-weight: 600;
    background-color: #e1e3f0;
    padding: 5px 10px;
    cursor: pointer;
    &:hover {
      background-color: white;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    }
  }
  .top-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 20px;
    border-bottom: #455cd9 solid 2px;

    @media only screen and (max-width: 550px) {
      flex-direction: column;
    }
    .info {
      background: #e1e3f0;
      padding: 10px 20px;
      border-radius: 10px;
      line-height: 25px;

      @media only screen and (max-width: 550px) {
        width: 100%;
        max-width: 300px;
        order: 2;
      }

      h4 {
        @media only screen and (max-width: 550px) {
          font-size: 20px;
        }
      }
      p {
        @media only screen and (max-width: 550px) {
          font-size: 14px;
        }
      }
    }
    img {
      max-height: 140px;
      border-radius: 10px;

      @media only screen and (max-width: 550px) {
        width: 100%;
        max-width: 300px;
      }
    }
  }
  .modal {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #e7e7e7c4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    gap: 10px;
    max-width: 400px;
    height: auto;
    z-index: 9999;
    padding: 10px;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h6 {
      text-align: center;
      margin: 0;
    }

    input {
      text-align: center;
      display: block;
      background: #ffffff;
      &:nth-child(3) {
        margin-bottom: 30px;
      }
    }

    p {
      margin-bottom: 15px;
    }
  }

  .online-mapped-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }
}
.tabel-number {
  font-weight: 700;
}
.switcher {
  display: flex;
  font-size: 1.2rem;
  gap: 5px;
  align-items: center;
  background-color: #e1e3f0;
  width: fit-content;
  padding: 5px;
  border-radius: 8px;
  text-align: center;
  height: fit-content;
  span {
    font-weight: 600;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    color: #5f5f5fb9;
    &:hover {
      background-color: white;
    }
  }

  .active {
    font-weight: 600;
    color: #000;
    background-color: white;
  }
}
.small {
  font-size: 0.85rem;
  gap: 5px;
  padding: 4px;
  border-radius: 6px;

  span {
    padding: 5px;
    border-radius: 3px;
  }
}

.add-ticket-modal {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  overflow: auto;
  max-height: 90vh;
  max-width: 90vw;
  outline: 1px solid #ccc;
  .modal-wrapper {
    position: relative;
    min-width: 100%;
    display: flex;
    overflow: auto;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    .scroll-wrapper {
      overflow: auto;
    }
    .modal-header {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 10px;
      .header {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        gap: 20px;

        .modal-title {
          flex: 1;
          padding: 0.3rem;
          font-size: 1.2rem;
          font-weight: 700;
          color: #15167e;
          text-align: center;
        }
        .on-sale {
          color: rgb(0, 131, 33);
          box-shadow: 0px 0px 5px 1px rgba(0, 131, 33, 0.5);
          border-radius: 5px;
        }
      }
    }

    .zone-numbers-detail {
      display: flex;
      justify-content: center;
      gap: 1rem;
    }

    .modal-form {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin: 0 auto;
      justify-content: center;

      .table-input {
        max-width: 130px !important;
        min-width: 80px !important;
        text-align: center;
        outline: none;
        background-color: white;
        border-radius: 3px;
        padding: 2px;
        height: 25px;
        margin: 0 !important;
        &:disabled {
          background-color: transparent;
        }
      }
    }
  }
  .maper-button-wrapper {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px 0;
  }
  .save-zone-rows-btn {
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    background: #48559f;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    font-weight: 600;
    &:hover {
      scale: 1.03;
    }
  }
  .modal-input {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: #efefef;
    border-radius: 4px;
    padding: 2px 8px;
    height: 35px;
    .modal-label {
      font-weight: 600;
    }
  }
  .modal-top-sticky {
    width: 99%;
    margin: 0 auto;
    position: sticky;
    border-radius: 10px;
    top: 0;
    z-index: 10;
    border-bottom: 2px dashed #ccc;
  }
}
.close-modal {
  justify-content: flex-end;
  display: flex;
  gap: 10px;
  cursor: pointer;
  svg {
    &:hover {
      scale: 1.2;
    }
  }
}
.table-input {
  max-width: 100px !important;
  min-width: 60px !important;
  text-align: center;
  outline: none;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px !important;
  padding: 2px;
  height: 22px;
  margin: 0 !important;
  &:disabled {
    background-color: transparent;
  }
}
.generator-container {
  .template-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-end;
    .set-template {
      display: flex;
      gap: 10px;
      align-items: center;
      width: fit-content;
      background-color: #e1e3f0;
      padding: 0 4px;
      border-radius: 8px;
      label {
        padding-left: 5px;
      }
      .template-select {
        margin: 0;
        padding: 1px;
        max-width: fit-content !important;
        outline: none;
        border-radius: 4px;
      }
    }
  }
  .preview-template {
    height: fit-content;
    display: flex;
    gap: 10px;
    align-items: center;
    width: fit-content;
    border-radius: 8px;
    background-color: #e1e3f0;
    padding: 5px 10px;
    font-size: 0.91rem;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      background-color: white;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    }
  }
  .tickets-categories-container {
    margin-top: 20px;
    padding: 0;

    input {
      text-align: center;
      display: block;
      background: #ffffff;
      margin: 0;
    }

    .disable-zone-checkbox {
      height: 20px;
    }

    .row {
      padding: 5px;
      width: 100%;
      margin: 0 auto;
      gap: 0;
      background-color: #fff;
      border-radius: 10px;
      margin-bottom: 10px;
      .invalid-input {
        outline: 2px solid yellow;
      }

      .event-input {
        background-color: rgba(69, 91, 217, 0.108);
      }

      .col-lg-6 {
        display: flex;
        flex-direction: column;
        width: 50%;
        justify-content: space-between;
        input {
          text-align: center;
          width: 100%;
        }
      }

      .add-tickets-right-col {
        border-left: 2px solid rgba(69, 91, 217, 0.505);
        .tickets-num-owner-input {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          gap: 3%;
          .tickets-num {
            flex: 1;
            min-width: 100px;
          }
          span {
            font-weight: 600;
          }
        }
        .event-input {
          margin-left: auto;
        }
        .price {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5%;

          img {
            opacity: 0.7;
            width: 20px;
            &:hover {
              opacity: 1;
              transform: scale(1.1);
            }
          }
        }
        .ticket-price {
          width: 80%;
          margin-right: auto;
          margin-left: 0;
        }
      }
    }
  }
  .add-category-icon {
    cursor: pointer;
    margin: 20px auto 15px auto;
    display: block;
    &:hover {
      scale: 1.1;
    }
  }
  .add-category-icon-zone {
    width: 100%;
    margin: 10px auto;
    cursor: pointer;
  }
  h6 {
    margin-left: 20px;
    margin-top: 20px;
  }

  .total-summ {
    padding: 1%;
    display: flex;
    gap: 5%;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .legend {
    flex-grow: 0;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 0.9rem;
    gap: 10px;
    div {
      width: 115px;
      height: 50px;
      display: block;
      color: #424242;
      text-align: center;
      padding: 5px 15px;
      border-radius: 8px;
    }
    .free {
      box-shadow: 0px 6px 5px -3px rgba(10, 67, 255, 0.849);
    }
    .online {
      box-shadow: 0px 6px 5px -3px rgba(150, 1, 1, 0.849);
    }
    .available {
      box-shadow: 0px 6px 5px -3px rgba(100, 100, 100, 0.849);
    }
    .selected {
      box-shadow: 0px 6px 5px -3px rgb(88, 203, 0);
    }
    .mixed {
      box-shadow: 0px 6px 5px -3px rgba(150, 50, 150, 0.849);
    }
  }
  // Owners-container
  .owners-container {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;

    .header {
      font-weight: 600;
      text-align: center;
      color: #15167e;
      padding-bottom: 5px;
      margin: 0 25px 0 10px;
      border-bottom: #15167e 2px dashed;
    }
    .open-modal {
      cursor: pointer;
      padding: 0.3rem;
      padding-left: 7px;
      border-radius: 0.3rem;
      background-color: white;
      box-shadow: 0px 0px 5px 1px rgba(7, 0, 108, 0.259);
      &:hover {
        scale: 1.1;
      }
    }
    .modal-wrapper-owners {
      padding: 10px;
      width: min(800px, 95vw);
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(227, 227, 227);
      border-radius: 10px;
      z-index: 9999;
      box-shadow: 0px 0px 11px rgba(124, 124, 124, 0.15);
    }
    .add-owners-modal {
      display: flex;

      .add-owners-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 10px;
        min-width: min(160px, 45%);
        max-width: min(500px, 45%);

        .add-owners-button {
          cursor: pointer;
          background-color: #f4cd46;
          border-radius: 15px;
          padding: 5px 10px;
          width: fit-content;
          font-weight: 600;
          &:hover {
            background-color: #d1aa1b;
          }
        }
        .textarea {
          border: none;
          outline: none;
          width: 100%;
          min-height: 250px;
          height: 100%;
          font-size: 0.8rem;

          padding: 0.7rem;
          text-decoration: none;
          border-radius: 0.2rem;
          &:focus-visible,
          &:focus {
            outline: 1px dotted #f4cd46;
          }
        }
        .filter-strings {
          font-size: 0.7rem;
          display: flex;
          gap: 5px;
          align-items: center;
          flex-wrap: wrap;
          span {
            cursor: pointer;
            background-color: #f4cd46;
            border-radius: 2px;
            padding: 2px 4px;
            width: fit-content;
            font-weight: 600;
            &:hover {
              background-color: #d1aa1b;
            }
          }
        }
        .owner-input {
          font-size: 0.8rem;
          width: 100%;
          border-radius: 3px !important;
          &:focus-visible,
          &:focus {
            outline: 1px dotted #f4cd46;
          }
        }
      }
    }
    .owners-list-scroll {
      overflow: auto;
      max-height: 100%;
      max-width: min(700px, 65%);
    }
    .owners-list {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 10px;
      max-height: 500px;

      .owners {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;
        .owner {
          background-color: #f2f2f2;
          border-radius: 4px;
          font-size: 0.85rem;
          padding: 1px 1px 1px 5px;
          display: flex;
          align-items: center;
          gap: 4px;
        }
        .remove-owner {
          cursor: pointer;
          width: 18px;
          height: 18px;
          transition: all 0.1s;
          &:hover {
            color: red;
          }
        }
      }
    }
  }
}
.cards-wrapper-online-tickets {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  .buttons-wrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

.zone-card-container-wrapper {
  background-color: #d1d1d149;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  position: relative;
  .on-sale-info {
    position: absolute;
    top: 8%;
    right: 5%;
  }
  .zone-info {
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    .zone-name {
      font-weight: bold;
    }
  }

  .zone-input {
    max-width: 100px !important;
    height: 35px;
    text-align: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .zone-add {
    height: 35px !important;
    margin-left: -10px !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .zone-toggle {
    height: 35px !important;

    &.red-button {
      background: rgba(163, 54, 54, 0.541) !important;
    }
  }
}
.zone-new-zone {
  background-color: #d1d1d149;
  padding: 10px;
  border-radius: 10px;
  display: grid;
  margin-bottom: 20px;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  flex-wrap: wrap;
  input {
    text-align: center;
  }
  button {
    grid-column: span 2;
  }
}
// Seats and rows display global
.rows-container {
  display: flex;
  user-select: none;
  min-width: min-content;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 60px 30px 10px;
  position: relative;
  cursor: grab;
  @media screen and (max-width: 600px) {
    padding-top: 100px;
  }

  &:active {
    cursor: grabbing;
  }

  .all-toggler {
    position: absolute;
    top: 0;
    left: 15px;
    z-index: 9;
  }
}
.seats-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .row-info {
    cursor: pointer;
    padding: 0 8px;
    width: 15px;
    box-shadow: 0 0 2px #aaa;
    font-size: 0.85rem;
    border-radius: 10px;
    min-width: fit-content;
    text-align: center;
  }
  .row-info-minimal {
    min-width: fit-content;
    color: #777;
    font-size: 0.5rem;
    font-weight: 700;
    scale: 1.75;
    margin: 0 10px;
    pointer-events: none;
  }

  .seats {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    min-height: 10px;
    max-height: 10px;
    min-width: 10px;
    max-width: 10px;
    outline: solid 3px #ccc;
    cursor: pointer;
    background-color: #efefef;
    transition: none !important;
    transition: margin 0.1s !important;
  }

  .sold-seat {
    background-color: #e2e2e2;
    pointer-events: none;
    outline: none;
  }
  .free-seat {
    &:hover {
      background-color: rgba(3, 29, 158, 0.45);
    }
  }
  .reserved-seat {
    cursor: pointer;
    font-size: 0.5rem;
    color: #fff;
    position: relative;
    margin: 4px;
    scale: 1.4;
    outline: none;
    background-color: rgba(3, 29, 158, 0.45);
    box-shadow: 0 0 0px 3px rgba(0, 48, 179, 0.65);
    .seat-number {
      font-family: monospace;
      font-weight: 600;
      font-size: 0.6rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .selected-row {
    outline: solid 2px #675ac69e;
    scale: 1.4;
    position: relative;
    margin: 1px;
    .seat-number {
      font-family: monospace;
      color: #888;
      font-weight: 600;
      font-size: 0.4rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .selected-row-minimal {
    scale: 3;
    color: rgba(0, 48, 179, 0.64);
  }
  .selected-seat {
    cursor: pointer;
    font-size: 0.5rem;
    color: #fff;
    position: relative;
    outline: none;
    background-color: rgba(3, 29, 158, 0.474);
    box-shadow: 0 0 0px 2px rgba(0, 48, 179, 0.64);
    scale: 1.1;
    .seat-number {
      font-weight: 600;
      position: absolute;
      font-size: 0.55rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.total-per-zone-container {
  min-width: 100%;
  margin: 1rem 0;
  overflow: hidden;
  border-radius: 10px;
  outline: 1px solid #ddd;
  .table-wrapper {
    overflow: auto;
  }
}
.add-zone-container {
  .add-zone-form-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
    .input-field-custom-wrapper {
      flex: 1;
    }
  }
}
.total-per-zone {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;

  .open-zone-button {
    cursor: pointer;
    font-weight: 600;
    border-radius: 0.3rem;
    padding: 0.1rem 0.8rem;
    background-color: #fff;
    box-shadow: 0px 0px 5px 1px rgba(127, 125, 184, 0.15);
    &.no-edit {
      background-color: transparent;
      cursor: not-allowed;
      pointer-events: none;
      box-shadow: none;
    }
  }

  th,
  td {
    padding: 8px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    text-wrap: none;
  }

  th {
    background-color: #f2f2f2;
  }

  p {
    margin: 0;
  }
}
.remove-ticket {
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    scale: 1.1;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 38px !important;
  height: 19px !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
  cursor: pointer;
}

.switch label:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.2s;
  border-radius: 50%;
}

.switch-input:checked + label {
  background-color: #007aff;
  box-shadow: 0px 0px 2px 0.5px #007aff;
}

input:checked + label:before {
  transform: translateX(19px);
}
