.print-ticket-manager-modal-window {
  // position: fixed;
  min-height: 99vh;
  min-height: 99svh;
  min-width: 99svw;
  min-width: 99vw;
  border-radius: 1rem;
  .buy-page-window {
    user-select: none;
    margin: 1rem auto;
    max-width: 800px;
    padding-bottom: 1rem;
    background: #ffffff92;
    box-shadow: 0px 0px 10px 2px #eeeeee;
    border-radius: 1rem;
    @media screen and (max-width: 600px) {
      border-radius: 0;
      margin: 0 0 5rem;
    }

    // EVENT INFO STYLES
    .event-info-container {
      overflow: hidden;
      position: relative;
      border-radius: 1rem 1rem 0 0;
      width: 100%;
      height: 200px;

      @media screen and (max-width: 600px) {
        border-radius: 0;
        margin: 0;
      }
      img {
        border-radius: 0 1rem 0 0;
        object-fit: cover;
        position: absolute;
        filter: blur(5px);
      }

      .image-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgba(90, 90, 90, 0.3) 100%);
        color: #fff;
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      }
      .event-name-wrapper {
        gap: 1%;
        .event-name {
          font-size: 2rem;
          font-weight: 600;
          text-shadow: 0px 0px 5px #000;
        }
      }
      .card-main-info {
        font-size: 1rem;
        text-transform: capitalize;
        font-weight: 400;
        text-shadow: 0px 0px 5px #000;
      }
    }
    .notification-card {
      padding: 2rem 0;
      text-align: center;
      max-width: 80%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    // BUY NAVIGATION
    .buy-navigation-wrapper {
      margin: 1rem;
      margin-left: auto;
      margin-right: 10%;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 1rem;
      @media screen and (max-width: 600px) {
        width: 90%;
        margin: 1% auto;
        justify-content: space-between;
        position: fixed;
        z-index: 9999;
        background: #fff;
        padding: 0.5rem;
        border-radius: 0.3rem;
        box-shadow: 0 0 5px #ccc;
        border-radius: 0.3rem;
        left: 50%;
        bottom: 0vh;
        bottom: 0svh;
        // bottom: -3%;
        transform: translate(-50%);
      }
    }
    .buy-navigation {
      display: flex;
      color: #777;
      align-items: center;
      gap: 1rem;
      box-shadow: 0px 0px 3px #bbb;
      padding: 0.3rem 0.8rem;
      border-radius: 0.3rem;
      font-size: 0.9rem;
      > :not(:last-child) {
        padding-right: 1rem;
        border-right: 2px solid #d7d7d7;
      }

      .ticket-number {
        font-weight: 600;
        font-size: 1rem;
        font-family: monospace;
      }
      .amount-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        span {
          font-weight: 600;
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
        &:hover {
          scale: 1.05;
        }
      }
      .disabled-button {
        cursor: not-allowed;
        opacity: 50%;
        &:hover {
          scale: 1;
        }
      }
    }

    .personalization {
      margin: 10px auto;
      width: 80%;
      border-radius: 1rem;
      outline: dashed 2px #ececec;
      padding: 1rem 2rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(to bottom, #fff 0%, #fff 80%, #f5f5f5bc 100%);
      @media screen and (max-width: 600px) {
        width: 98%;
        padding: 1rem 0.5rem 2rem;
      }

      // TICKET HEADING + CREDENTIALS
      .ticket-heading {
        position: relative;
        display: flex;
        margin-right: auto;
        padding-bottom: 1rem;
        border-bottom: dashed 2px #ececec;
        width: 100%;
        gap: 1rem;
        @media screen and (max-width: 600px) {
          flex-wrap: wrap;
          padding: 0 0.5rem 1rem;
        }

        .ticket-header-left {
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
          flex: 0;
          @media screen and (max-width: 600px) {
            flex: 1;
          }
        }

        .ticket-title {
          font-size: 1.5rem;
          font-weight: 600;
          padding-right: 3.5rem;
        }

        .credentials {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-end;
          gap: 1.5rem;

          .buy-input {
            .input-field-custom {
              position: relative;
              font-weight: 500;
              height: 25px;
              box-shadow: 0 8px 7px -10px #7d7d7d;
              width: 130px;

              &:placeholder-shown {
                box-shadow: 0 8px 10px -8px #ff000090;
              }
              &:focus {
                width: 180px;
                outline: none;
                box-shadow: 0 8px 10px -10px #0064d0;
              }
            }

            .input-field-error {
              box-shadow: 0 10px 10px -8px #ff0000;
              &:focus {
                outline: none;
                box-shadow: 0 8px 10px -10px #0064d0;
              }
            }
            .input-field-error-message {
              position: absolute;
              bottom: -1.5rem;
              left: 0.2rem;
              text-wrap: nowrap;
            }
          }
          .email-input {
            .input-field-custom {
              min-width: min-content;
            }
          }
        }
        .remove-ticket-btn-position {
          position: absolute;
          right: 0;
          top: 1%;
        }
      }

      // TICKET CARDS STYLES
      .ticket-cards {
        width: fit-content;
        padding: 1rem 0.5rem;
        border-radius: 0.5rem;
        display: flex;
        gap: 1.5rem;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .ticket-card {
          cursor: pointer;
          border-radius: 1rem;
          padding: 1rem;
          width: fit-content;
          min-width: 130px;
          box-shadow: 0 0 16px 1px #cccccc4c;
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          transition: width 0.3s ease, transform 0.3s ease, scale 0.3s ease-in-out;

          .ticket-card-header {
            display: flex;
            align-items: center;
            margin-left: 0.2rem;
            padding-bottom: 0.8rem;
            gap: 1rem;
            justify-content: space-between;
            border-bottom: 1px solid #e4e4e4;
            width: 100%;
            min-width: fit-content;

            .ticket-amount {
              margin-right: auto;
              padding: 0.1rem 0.5rem;
              text-align: center;
            }

            .ticket-point {
              width: 14px;
              height: 14px;
              border-radius: 50%;
              border: 2px solid #aaaaaa;
            }

            .selected-ticket-point {
              border: 5px solid;
            }
          }

          .ticket-card-content {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            margin-left: -0.2rem;

            .zone-text {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 90px;
              transition: max-width 0.4s ease;
            }

            svg {
              color: #999;
              height: 1.1rem;
            }
          }

          &.disabled-ticket-card {
            opacity: 0.4;
            pointer-events: none;
          }
          &.selected-ticket-card {
            scale: 1.03;
            cursor: pointer;
            opacity: 1;

            .ticket-card-content .zone-text {
              max-width: 200px;
            }
          }
        }
      }
    }

    // TICKET BILL
    .ticket-bill {
      display: flex;
      margin: 2% auto;
      width: 80%;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0.8rem;
      border-radius: 0.8rem;
      box-shadow: 0px 0px 5px rgb(227, 227, 227);
      height: fit-content;
      transition: all 0.2s;

      @media screen and (max-width: 600px) {
        width: 95%;
        padding: 0.5rem 0.3rem;
      }

      .ticket-bill-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 1rem;
        padding: 0 1rem 0.5rem;
        @media screen and (max-width: 600px) {
          padding: 0.5rem 0.3rem;
        }

        .ticket-bill-left {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          width: fit-content;
          cursor: pointer;

          > :nth-child(2) {
            padding-top: 0.4rem;
            border-top: 1px solid #e0e0e0;
          }
        }

        .ticket-number-text {
          font-size: 0.8rem;
          color: #888;
          font-family: monospace;
          width: 1.2rem;
          height: 1.2rem;
          border-radius: 100px;
          outline: dashed 2px;
          font-weight: 800;
          text-align: center;
        }

        .ticket-bill-right {
          display: flex;
          align-items: center;
          gap: 0.3rem;
        }
        .flex-container {
          display: flex;
          align-items: center;
          gap: 0.8rem;
          flex-wrap: wrap;
          .tag-icon {
            display: none;
          }
        }
      }
    }

    .ticket-swiper-wrapper {
      position: relative;
    }

    // CUSTOM SWIPER NAVIGATION

    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: var(--swiper-navigation-top-offset, 60%);
      width: calc(var(--swiper-navigation-size) / 44 * 27);
      height: var(--swiper-navigation-size);
      margin-top: calc(0px - var(--swiper-navigation-size) / 2);
      color: #777;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper-button-next {
      right: 2%;
      .vertical-button {
        transform: rotate(180deg) translateY(50%);
        writing-mode: vertical-lr;
        text-orientation: mixed;
        display: flex;
        padding: 0.4rem 0.2rem;
        gap: 0.5rem;
        border-radius: 10rem;
        box-shadow: 0px 0px 3px 1px #dadada;

        span {
          font-weight: 600;
          padding-bottom: 0.2rem;
        }
      }
    }
    .swiper-button-prev {
      left: 2%;
    }

    //CUSTOM SWIPER PAGINATION
    .ticket-swiper-wrapper {
      height: 100%; /* Allow for vertical swiping */
      overflow: hidden; /* Prevent content from breaking layout */
    }

    .swiper-slide {
      height: auto; /* Ensure slides adjust to content */
    }
    .swiper-pagination-horizontal {
      padding-bottom: 1%;
    }
    .swiper-pagination-bullet {
      text-align: center;
      font-weight: 400;
      font-size: 1rem;

      color: #8d8d8d;
      background: transparent;
      opacity: 1;

      width: 3rem;
      height: fit-content;
      border-radius: 0;
    }

    .swiper-pagination-bullet-active {
      box-shadow: 0 8px 6px -8px #007aff;
      color: #00112b;
      font-weight: 700;

      scale: 1.1;
    }

    // TOTAL PRICE

    .total-price {
      margin: 10px auto;
      width: 80%;
      height: fit-content;
      border-radius: 1rem;
      outline: dashed 2px #ececec;
      padding: 1rem 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(to bottom, #fff 0%, #fff 80%, #f2f2f2bc 100%);

      @media screen and (max-width: 600px) {
        width: 95%;
        padding: 1rem;
      }
      // PAYMENT FORM

      .payment-header {
        display: flex;
        flex-direction: column;
        margin: 1rem auto 0;
        padding: 1rem 0;
        border-bottom: dashed 2px #ececec;
        border-top: dashed 2px #ececec;
      }

      .payment-form {
        position: relative;
        width: 100%;
        margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        gap: 1.4rem;
        padding-bottom: 4rem;

        @media screen and (max-width: 600px) {
          position: static;
        }
        .card-element {
          width: 100%;
        }

        #card-error {
          color: rgb(166, 12, 12, 0.7);
          font-size: 0.8rem;
          font-weight: 600;
          align-self: flex-start;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          padding: 0.2rem 0.5rem;
          border-radius: 0.3rem;
          outline: 2px solid rgba(166, 12, 12, 0.4);
          background: rgb(252, 244, 244);
        }

        .success-text {
          font-size: 0.85rem;
          color: #777;
        }
        .payment-message-wrapper {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          opacity: 0.8;
          width: 100%;
        }
        .payment-error-header {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
        .payment-error-message {
          font-size: 0.9rem;
          font-weight: 500;
          color: rgb(166, 12, 12);
        }

        .payment-errors {
          display: flex;
          gap: 0.5rem;
          flex-wrap: wrap;
        }

        .payment-button-wrapper {
          position: absolute;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          width: 100%;
          @media screen and (max-width: 600px) {
            background: #fff;
            padding: 0.5rem;
            border-radius: 0.3rem;
            box-shadow: 0 0 5px #ccc;
            position: fixed;
            z-index: 9999;
            width: 96%;
            left: 2%;
            margin: 0 auto;
            bottom: 1svh;
            bottom: 1vh;
          }
          .secondary-buy-button {
            background: transparent;
            color: #777;
            &:hover {
              background: #f2f2f2;
            }
            box-shadow: 0 0 4px #bbb;
          }
        }
        // TIMER OF RESERVATION
        .timer-wrapper {
          max-width: 250px;
          flex: 1;
          height: 2rem;
          border-radius: 0.2rem;
          position: relative;
          overflow: hidden;
          background-color: rgba(0, 123, 255, 0.4);

          display: flex;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: 600px) {
            background: transparent;
            overflow: visible;
          }
        }

        .timer-text {
          font-size: 0.9rem;
          font-weight: 600;
          color: #f4f9ff;
          text-shadow: 0 0 3px #007bff8d;
          z-index: 1;

          @media screen and (max-width: 600px) {
            background: transparent;
            color: #555;
            text-shadow: none;
            text-align: center;
            font-size: 0.8rem;
            svg {
              display: none;
            }
          }
        }

        .progress-bar {
          position: absolute;
          height: 100%;
          left: 0;
          background-color: rgba(0, 123, 255, 0.4);
          @media screen and (max-width: 600px) {
            background: transparent;
          }
        }
      }
    }
    .total-price-button-wrapper {
      margin-top: 1rem;
      align-self: flex-end;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
      justify-content: end;
      @media screen and (max-width: 600px) {
        align-self: center;
        justify-content: space-between;
        .payment-footer-btn {
          display: none;
        }
      }
    }

    .currency-select-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      position: relative;
      box-shadow: 0 0 3px #bbb;
      border-radius: 0.3rem;
      padding: 0.3rem 0.5rem;
      cursor: pointer;
      overflow: hidden;
      width: 10rem;
      transition: width 0.5s ease;

      &.currency-select-container-open {
        width: 19.9rem;
      }

      .currency-text {
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        gap: 0.3rem;
        font-weight: 500;
        color: #777;
      }

      .custom-select {
        display: flex;
        gap: 0.5rem;
      }

      .selected-option-currency {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        padding: 0.1rem 0.2rem;
        border-radius: 100px 1rem 1rem 100px;

        &:hover {
          background-color: #f0f0f0;
          box-shadow: 0 0 2px #888;
        }
      }
      .dropdown-item-currency {
        cursor: pointer;
        padding: 0.1rem 0.2rem;
        border-radius: 100px 1rem 1rem 100px;
        &:hover {
          background-color: #f0f0f0;
          box-shadow: 0 0 2px #888;
        }

        .currency-item {
          display: flex;
          align-items: center;
          gap: 0.3rem;
        }
      }
      .dropdown-menu-currency {
        display: flex;
        gap: 0.5rem;
      }

      .currency-flag {
        border-radius: 100px;
        width: 22px;
        height: 22px;
        outline: 2px solid #ccc;
        object-fit: cover;
      }
    }
  }

  // MAPPED-SEATS
  .mapper-wrapper {
    width: 80%;
    margin: 1rem auto;
    position: relative;
    @media screen and (max-width: 600px) {
      width: 90%;
    }
    // SEATS MODAL
    .modal-seats {
      width: 100%;
      max-height: 300px;
      overflow: auto;
      border-radius: 1.8rem;
      box-shadow: 0 -2px 7px 3px #f5f5f5;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .zone-info-not-available {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .zone-info-container {
      overflow: hidden;
      max-height: 50px;
      padding: 0.5rem;
      color: #aaa;
      position: absolute;
      top: 0;
      backdrop-filter: blur(5px);
      z-index: 999;
      width: 100%;
      border-radius: 1.4rem;
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;

      .seats-remaining-display {
        flex: 1;
        min-height: 1.5rem;
        margin-left: auto;
        max-width: 35%;
        .progress-bar {
          width: 100%;
          height: 100%;
          position: relative;
          border-radius: 2rem;
          .progress-bar-text {
            z-index: 9999;
            color: white;
            font-size: 0.9rem;
            filter: drop-shadow(0 0 4px #555);
            font-family: monospace;
          }
          .progress-bar-inner {
            position: absolute;
            transition: width 0.5s ease-in-out;
            height: 100%;
          }
        }
      }
      .ticket-circles {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        flex-wrap: wrap;
        color: white; /* Text color contrasting the progress bar */
        cursor: pointer;

        .ticket-circle {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          display: flex;
          font-size: 0.9rem;
          align-items: center;
          justify-content: center;
          font-weight: 800;
          font-family: monospace;
        }
      }
    }
  }
}
// IMAGE MAPPER
.image-map-container {
  max-width: 500px;
  margin: 0 auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  .buy-img-mapper {
    width: 100%;
    height: auto;
  }
}

// COMMON CLASSES
.flex-between {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.remove-ticket-btn {
  cursor: pointer;
  height: 18px;
  color: #777;
  &:hover {
    color: rgb(181, 27, 0);
    scale: 1.1;
  }
}
.pointer-hover {
  cursor: pointer;
  &:hover {
    scale: 1.05;
  }
}

.info-text {
  font-size: 0.7rem;
  color: #888;
}

.currency-styles {
  color: #999;
  font-size: 1.1rem;
  font-weight: 600;
}

.ticket-badge {
  font-size: 0.8rem;
  width: fit-content;
  font-weight: 500;
  padding: 0.1rem 0.6rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.text-with-icon {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 1.2rem;
}
.justfy-center {
  justify-content: center;
  padding-left: 0.5rem;
}

.text-nobrake {
  text-overflow: ellipsis;
  white-space: nowrap;
}
.main-text {
  color: #00112b;
  font-size: 1.2rem;
  font-weight: 600;
}
.main-text-price {
  font-size: 1.3rem;
  font-weight: 600;
  color: #00112b;
}
.secondary-main-text {
  font-size: 0.95rem;
  font-weight: 600;
  color: #454545;
}
.sub-main-text {
  font-size: 0.85rem;
  font-weight: 600;
  color: #454545;
}
.secondary-text {
  font-size: 0.8rem;
  color: #777;
}

.boder-bottom-fade {
  padding-bottom: 0.3rem;
  margin-bottom: 0.3rem;
  border-bottom: 1px solid #ddd;
}

.toast-flex {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  .border-bottom-fade {
    padding-bottom: 0.4rem;
    margin-bottom: 0.4rem;
    border-bottom: 1px solid #eee;
  }
}

.button-with-icon {
  cursor: pointer;
  width: fit-content;
  padding: 0.3rem 0.6rem;
  background: #fff;
  color: #888;
  gap: 0.5rem;
  border-radius: 10rem;
  box-shadow: 0px 0px 3px #a7a7a7;
  font-weight: 600;
  &:hover {
    scale: 1.05;
    box-shadow: 0px 0px 5px #a7a7a7;
  }
}

.input-fields-wrapper {
  display: flex;
}

// CUSTOM SELECT
.custom--dropdown-container {
  position: relative;
  flex: 1;
  .input-wrapper {
    position: relative;
    .clear-button {
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
      cursor: pointer;
      color: #888;
    }
  }
}

.custom--dropdown-container .dropdown-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom--dropdown-container .dropdown-menu-custom {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 5px;
  position: absolute;
  -webkit-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px);
  border: 1px solid #dbdbdb;
  border-radius: 0.2rem;
  overflow: auto;
  background-color: #fff;
  z-index: 99;
  max-height: 312px;
  min-height: 40px;
  width: 100%;
  .center {
    text-align: center;
  }
  svg {
    margin-right: 0.2rem;
  }
}

.custom--dropdown-container .dropdown-menu-custom.alignment--left {
  left: 0;
}

.custom--dropdown-container .dropdown-menu-custom.alignment--right {
  right: 0;
}

.custom--dropdown-container .dropdown-item {
  padding: 0.5rem 1.2rem;
  padding-left: 2rem;
  cursor: pointer;
  -webkit-transition: background-color 0.35s ease;
  transition: background-color 0.35s ease;
  border-radius: 0.3rem;
  font-weight: 500;
  color: #777;
  font-size: 0.9rem;
}

.custom--dropdown-container .dropdown-item:hover {
  background-color: rgba(0, 115, 255, 0.05);
  color: #007aff;
}

.custom--dropdown-container .dropdown-item.selected {
  color: #007aff;
  font-weight: 600;
  padding-left: 0.5rem;
}

.no-results {
  font-size: 0.9rem;
  color: #007aff;
  font-weight: 600;
  padding: 0.5rem;
}
