.qr-scanner-container {
  max-width: 450px;
  height: 110vh;
  .qr-sacnner-nav {
    justify-content: flex-end;

    .nav-item {
      justify-content: center;
    }
  }
  #html5-qrcode-anchor-scan-type-change {
    // display: none !important;
  }
  select#html5-qrcode-select-camera {
    display: block !important;
    margin: 10px auto !important;
    border: 1px solid #7c8ce4 !important;
    border-radius: 10px !important;
    padding: 3px !important;
  }

  #reader__dashboard_section {
    padding-top: 0 !important;
  }
  #reader__dashboard_section_csr {
    span {
      margin-right: 0 !important;
    }
    button {
      border: none !important;
      background: #f4cd46 !important;
      padding: 3px 10px !important;
      border-radius: 10px !important;
      color: #343434 !important;
    }
  }

  img[alt="Info icon"] {
    display: none;
  }
  #html5-qrcode-button-camera-permission,
  .controller-logout-btn {
    background-color: #f4cd46;
    border: none;
    font-size: 0;
    border-radius: 10px;
    padding: 3px 15px;
    margin-top: 0;
  }
  #html5-qrcode-button-camera-permission::before {
    content: "Dozvolite Pristup Kameri";
    color: #343434;
    font-size: 16px;
    font-weight: 600;
  }
  .controller-logout-btn {
    font-size: 14px;
    margin: 0;
    min-width: fit-content;

    img {
      width: 17px;
      margin-left: 10px;
    }

    &:hover {
      img {
        transform: translateX(2px);
      }
    }
  }

  .qr-message {
    width: 95%;
    margin: 5px auto;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    .zone {
      font-size: 36px;
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
    }
    span {
      font-size: 22px;
      color: #fff;
    }

    p {
      color: #fff;
      font-size: 32px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
    }

    .loader {
      width: 158px;
      height: 158px;
      border: 15px solid #fff;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 750ms linear infinite;
      margin-left: 20px;
    }
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .success-scan {
    background: #398f00;
  }

  .failed-scan {
    background: #ae1700;
  }

  .scan-in-process {
    background-color: #d1b200;
    color: #343434;
  }

  .error-scan {
    background-color: #6f00b4;
  }
  h3 {
    text-align: center;
  }
  .entrance {
    margin: 0px auto;
    padding: 3px;
    color: #fff;
    border-radius: 10px;
    width: 95%;
  }
}
.entrance-controller-login-section {
  width: 100vw;
  height: 100vh;
  background-image: url("assets/images/controller_login_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
